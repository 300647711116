import React, { useLayoutEffect } from 'react'
import Card from './card'
import List from './list'
import Banner from './banner'

export default function Products() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="bg-cream">
      <Banner />
      <List />
    </div>
  )
}
