import React from 'react'

export default function Banner() {
  return (
    <section className="  min-h-[300px] md:min-h-[550px]  flex items-center  products-banner bg-no-repeat bg-center bg-cover  blade-top-padding-lg blade-bottom-padding ">
      <div className="grid gap-2 md:gap-3 lg:gap-5 text-center w-container px-4 blade-top-padding-lg">
        <h2 className="title w-full text-dark md:block hidden font-semibold text-center ">
          Our Brochures
        </h2>
        <h3 className="title md:hidden leading-snug block w-full text-dark font-semibold text-center ">
          Our Brochures
        </h3>
        <h6 className="text-center text-dark  text-opacity-80 leading-tight font-medium">
          Includes every detail you need.
        </h6>
      </div>
    </section>
  )
}
