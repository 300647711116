import React from 'react'
import one from 'assets/partners/investor1.svg'
import two from 'assets/partners/investor2.svg'
import three from 'assets/partners/investor3.svg'
import four from 'assets/partners/investor4.svg'
import six from 'assets/partners/investor6.svg'
import five from 'assets/partners/investor5.svg'

export default function Investors() {
  return (
    <div
      className="investors-wrapper  blade-top-padding-lg  blade-bottom-padding-sm"
      id="investors"
    >
      <div className="blade-top-padding blade-bottom-padding px-5 text-center">
        <span className="text-3xl md:text-4xl 2xl:text-5xl title font-medium text-dark">
          Our Partner Brands
        </span>
      </div>
      <div className=" mx-auto blade-bottom-padding-lg max-w-screen-md">
        <div className="grid grid-cols-2  md:grid-cols-3 px-2 lg:grid-cols-3 gap-y-2 gap-x-4 md:gap-5 lg:gap-7  xl:gap-9">
          <Tile
            text="Kajaria"
            icon={one}
            title="partner brand for fitu floors and decors"
          />
          <Tile
            icon={two}
            text="Jaquar"
            title="partner brand for fitu floors and decors"
          />
          <Tile
            text="johnson tiles"
            icon={five}
            title="partner brand for fitu floors and decors"
          />
          <Tile
            text="varmora"
            icon={three}
            title="partner brand for fitu floors and decors"
          />
          <Tile
            icon={four}
            text="cera india"
            title="partner brand for fitu floors and decors"
          />
          <Tile
            icon={six}
            text="lexo ceramics"
            title="partner brand for fitu floors and decors"
          />
        </div>
      </div>
    </div>
  )
}

function Tile({
  title,
  icon,
  text,
}: {
  text?: string
  title: string
  icon: string
}) {
  return (
    <article className="focus-visible:outline-blueGreen outline-offset-2 focus-visible:outline-offset-2   ">
      <div className="grid place-content-center d mb-2 partner-tile border-2 border-brown border-opacity-20 rounded-xl px-3 border-solid ">
        <img
          src={icon}
          alt={title}
          className="w-full max-w-[300px] md:h-[130px] hover:scale-[1.05] transition-all duration-300 ease-in-out lg:h-[120px]  object-contain object-center"
        />
      </div>
      <div className="grid place-content-center text-center">
        <span className="text-lg capitalize font-medium text-dark">{text}</span>
      </div>
    </article>
  )
}
