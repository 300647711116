import React, { useLayoutEffect } from 'react'
import Card from './card'
import { ListFloors, ListDecors } from './list'
import { TitleFloors, TitleDecors } from './title'

import Banner from './banner'

export default function Products() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="bg-cream">
      <Banner />
      <div className="blade-top-padding blade-bottom-padding">
        <TitleFloors />
        <ListFloors />
      </div>
      <div className="bg-darkcream blade-top-padding blade-bottom-padding">
        <TitleDecors />
        <ListDecors />
      </div>
    </div>
  )
}
