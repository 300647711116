import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'


// project is in the default account for rakesh gupta.

// rakesh.webg@gmail.com
// get in touch with admin for password.
const firebaseConfig = {
  apiKey: "AIzaSyCXtUT4gW1XqAo9w_DU87Xkib4e5MDic00",
  authDomain: "fitu-floors.firebaseapp.com",
  projectId: "fitu-floors",
  storageBucket: "fitu-floors.appspot.com",
  messagingSenderId: "310841179041",
  appId: "1:310841179041:web:15867163a7083af9c0e9e0",
  measurementId: "G-VVWP3MY198"
}
export const app = initializeApp(firebaseConfig)

export const db = getFirestore(app)
export const storage = getStorage(app)
