import React, { useState } from 'react'
import { z } from 'zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { MasterBtn } from 'atoms/buttons'
import emailIcon from 'assets/icons/email.svg'
import phoneIcon from 'assets/icons/phone.svg'
import locationIcon from 'assets/icons/location.svg'
import emailjs from '@emailjs/browser'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

type FormProps = {
  formTitle?: string
  withInfo?: boolean
   trigger: string
}

const formSchema = z.object({
  name: z
    .string()
    .min(1, 'Name is required.')
    .max(30, 'Name can not be more than 30 characters.'),
  location: z.string().min(2, 'Please enter a valid location'),
  email: z
    .string()
    .email('Invalid email address.')
    .min(1, 'Email is required.'),
  phone: z.string().length(10, 'Phone number should be of 10 digits.'),
  message: z.string(),
})

export type FormFieldSchema = z.infer<typeof formSchema>

export default function Form({ formTitle = '', withInfo = true , trigger }: FormProps) {
  const [isLoading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormFieldSchema>({
    resolver: zodResolver(formSchema),
  })

  const onSubmit: SubmitHandler<FormFieldSchema> = (data) => {
    setLoading( true)

     const  payload = Object.assign(data, { trigger: trigger})
    emailjs
      .send('service_hzp6khe', 'template_ribl42c', payload, '_V65hKtT9dOtgz_cY')
      .then(
        () => {
          reset()
          toast('Form submitted succesfully!')
        },
        () => {
          toast('Could not submit the form')
        },
      )
      .finally(() => setLoading(false)) 
  }

  return (
    <>
      <ToastContainer position="top-right" theme="light" />

      <section className="blade-bottom-padding-sm">
        <section className="blade-top-padding blade-bottom-padding-lg">
          <div>
            {formTitle && (
              <div className="">
                <h3
                  className="font-semibold  mx-auto text-center leading-normal blade-bottom-padding-sm lg:block hidden text-slate-600"
                  dangerouslySetInnerHTML={{ __html: formTitle }}
                />
                <h4
                  className="font-bold mx-auto text-center leading-normal blade-bottom-padding-sm lg:hidden block max-w-sm w-11/12 capitalize"
                  dangerouslySetInnerHTML={{ __html: formTitle }}
                />
              </div>
            )}

            {withInfo && (
              <div className="grid  grid-cols-1 md:grid-cols-3  max-w-screen-xl gap-6 mx-auto md:w-11/12 md:px-6 px-3 lg:px-10 ">
                <article className="p-4 rounded-lg border-1 border-solid border-slate-200 bg-darkcream flex flex-col gap-2">
                  <div className="flex items-center gap-3">
                    <img
                      src={emailIcon}
                      alt="email icon"
                      height={24}
                      width={24}
                      className="h-6 w-6 object-contain object-center"
                    />
                    <h6 className="font-semibold text-slate-900">Email</h6>
                  </div>

                  <div className="flex flex-col gap-1.5 flex-1 justify-start pb-1  items-start pt-4 ">
                    <a
                      href="mailto:enquiries@fitufloorsanddecors.com"
                      className="text-base font-normal hover:underline underline-offset-2 decoration-from-font focus-visible:outline-none outline-none focus-visible:underline"
                    >
                      enquiries@fitufloorsanddecors.com
                    </a>
                  </div>
                </article>

                <article className="p-4 rounded-lg border-1 border-solid border-slate-200 bg-darkcream flex flex-col gap-2">
                  <div className="flex items-center gap-3">
                    <img
                      src={phoneIcon}
                      alt="email icon"
                      height={24}
                      width={24}
                      className="h-6 w-6 object-contain object-center"
                    />
                    <h6 className="font-semibold text-slate-900">Phone</h6>
                  </div>

                  <div className="flex flex-col gap-1.5 items-start pt-4 ">
                    <a
                      href="tel:+919652306999"
                      className="text-base font-normal hover:underline underline-offset-2 decoration-from-font focus-visible:outline-none outline-none focus-visible:underline"
                    >
                      Mobile: +91 96523 06999
                    </a>
                    <a
                      href="tel:+919652444311"
                      className="text-base font-normal hover:underline underline-offset-2 decoration-from-font focus-visible:outline-none outline-none focus-visible:underline"
                    >
                      Mobile: +91 96524 44311
                    </a>

                    <a
                      href="tel:+919885183931"
                      className="text-base font-normal hover:underline underline-offset-2 decoration-from-font focus-visible:outline-none outline-none focus-visible:underline"
                    >
                      Mobile: +91 98851 83931
                    </a>
                  </div>
                </article>

                <article className="p-4 rounded-lg border-1 border-solid border-slate-200 bg-darkcream flex flex-col gap-2">
                  <div className="flex items-center gap-3">
                    <img
                      src={locationIcon}
                      alt="location icon"
                      height={24}
                      width={24}
                      className="h-6 w-6 object-contain object-center"
                    />
                    <h6 className="font-semibold text-slate-900">Location</h6>
                  </div>

                  <div className="flex flex-col gap-1.5 items-start pt-4  justify-end pb-1  flex-1 ">
                    <span className="text-base font-normal  underline-offset-2 decoration-from-font ">
                      Pillar no 234, mahmood, Wadi e, 4-25-51/1/A, opp. fish
                      building, Suleman Nagar, Hyderabad, Telangana 500030
                    </span>

                    <a
                      target="_blank"
                      href="https://maps.app.goo.gl/23cKk6MF2L54ebGr8"
                      className="  p-1 rounded-full mt-4 text-base  overflow-hidden font-normal  underline-offset-2 decoration-from-font focus-visible:outline-none outline-none focus-visible:underline"
                    >
                      <div className="bg-[#4285F4]  gap-3 text-sm   hover:outline   flex items-center  text-white  font-semibold  pl-2  pr-5 py-1.5 rounded-full">
                        <div className="h-8 w-8 bg-white rounded-full p-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 32 32"
                            id="google-maps"
                          >
                            <path
                              fill="#4285f4"
                              d="M25.3959 8.8345l-.0039.0038c.0837.2319.1617.4667.2285.7062C25.5527 9.3047 25.48 9.067 25.3959 8.8345zM16 2.23L8.929 5.1593 12.9916 9.222A4.2486 4.2486 0 0 1 19.0208 15.21L25 9.23l.392-.392A9.9872 9.9872 0 0 0 16 2.23z"
                            />
                            <path
                              fill="#ffba00"
                              d="M16,16.4733A4.25,4.25,0,0,1,12.9916,9.222L8.929,5.1593A9.9683,9.9683,0,0,0,6,12.23c0,4.4057,2.2651,7.1668,4.93,10,.1787.1828.3274.3852.4959.5746l7.5608-7.5609A4.2341,4.2341,0,0,1,16,16.4733Z"
                            />
                            <path
                              fill="#0066da"
                              d="M16,2.23a10,10,0,0,0-10,10,11.0918,11.0918,0,0,0,.5454,3.4546l12.8505-12.85A9.9563,9.9563,0,0,0,16,2.23Z"
                            />
                            <path
                              fill="#00ac47"
                              d="M16.9011,29.12a21.83,21.83,0,0,1,4.032-6.8966C23.7976,19.3129,26,16.636,26,12.23a9.9585,9.9585,0,0,0-.6041-3.3958l-13.97,13.97A18.0436,18.0436,0,0,1,15.0173,29.08.9975.9975,0,0,0,16.9011,29.12Z"
                            />
                            <path
                              fill="#0066da"
                              d="M10.93 22.23c.1787.1828.3274.3852.4959.5746h0C11.257 22.6155 11.1083 22.4131 10.93 22.23zM7.207 7.4637A9.9357 9.9357 0 0 0 6.45 9.2566 9.9429 9.9429 0 0 1 7.207 7.4637zM6.45 9.2566a9.9522 9.9522 0 0 0-.398 1.9513A9.9537 9.9537 0 0 1 6.45 9.2566z"
                              opacity=".5"
                            />
                            <path
                              fill="#fff"
                              d="M15.1957 29.3989c.02.0248.0445.0422.0664.0644C15.24 29.4411 15.2156 29.4236 15.1957 29.3989zM15.7874 29.7429l.04.0066zM13.6216 25.9269c-.0371-.067-.0679-.1382-.1059-.2047C13.5533 25.789 13.5849 25.86 13.6216 25.9269zM15.0173 29.08q-.3069-.9036-.6906-1.7566C14.5793 27.8937 14.8127 28.4771 15.0173 29.08zM15.5269 29.6563c-.0229-.0112-.0463-.0207-.0684-.0338C15.4809 29.6356 15.5036 29.6452 15.5269 29.6563zM19.7117 23.7529c-.249.3474-.4679.7125-.6927 1.0741C19.2431 24.465 19.4633 24.1006 19.7117 23.7529z"
                            />
                            <polygon
                              fill="#fff"
                              points="23.322 19.553 23.322 19.553 23.322 19.553 23.322 19.553"
                            />
                            <path
                              fill="#fff"
                              d="M17.0468 28.774h0q.3516-.887.7561-1.7428C17.5316 27.6006 17.2812 28.1826 17.0468 28.774zM18.68 25.3584c-.2879.4957-.55 1.0068-.8 1.5242C18.13 26.3647 18.3931 25.8547 18.68 25.3584z"
                            />
                            <path
                              fill="#ea4435"
                              d="M8.929,5.1593A9.9683,9.9683,0,0,0,6,12.23a11.0918,11.0918,0,0,0,.5454,3.4546L13,9.23Z"
                            />
                          </svg>
                        </div>
                        View on Google Maps
                      </div>
                    </a>
                  </div>
                </article>
              </div>
            )}
            <div className=" max-w-screen-xl mx-auto md:w-11/12 md:px-6 px-3 lg:px-10  ">
              <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                className=" flex flex-col  gap-4 md:gap-6  py-6 px-3 sm:p-5 md:p-8 lg:p-12 xl:p-16 2xl:p-20  border-1 border-solid rounded-lg  mt-6 border-slate-200 bg-darkcream"
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label
                      htmlFor="name"
                      className="grid gap-1 text-base md:text-lg "
                    >
                      Name*
                      <input
                        className="bg-white bg-opacity-40 text-base md:text-lg 2xl:text-lg font-normal placeholder:text-slate-400  text-slate-900 px-3 py-2.5 focus-visible:outline-none outline-none border-1 border-solid border-slate-200 focus-visible:border-slate-500 rounded-lg  "
                        id="name"
                        placeholder="Please enter your full name."
                        {...register('name')}
                      />
                      {errors.email && (
                        <span className="text-red-700 font-regular text-sm lg:text-base leading-tight">
                          {errors.name?.message}
                        </span>
                      )}
                    </label>
                  </div>

                  <div>
                    <label
                      htmlFor="email"
                      className="grid gap-1 text-base md:text-lg "
                    >
                      Email Address*
                      <input
                        className="bg-white bg-opacity-40 text-base md:text-lg 2xl:text-lg font-normal placeholder:text-slate-400  text-slate-900 px-3 py-2.5 focus-visible:outline-none outline-none border-1 border-solid border-slate-200 focus-visible:border-slate-500 rounded-lg  "
                        id="email"
                        type="email"
                        inputMode="email"
                        placeholder="Enter an active email address."
                        {...register('email')}
                      />
                      {errors.email && (
                        <span className="text-red-700 font-regular text-sm lg:text-base leading-tight">
                          {errors.email?.message}
                        </span>
                      )}
                    </label>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label
                      htmlFor="location"
                      className="grid gap-1 text-base md:text-lg "
                    >
                      Location*
                      <input
                        className="bg-white bg-opacity-40 text-base md:text-lg 2xl:text-lg font-normal placeholder:text-slate-400  text-slate-900 px-3 py-2.5 focus-visible:outline-none outline-none border-1 border-solid border-slate-200 focus-visible:border-slate-500 rounded-lg  "
                        id="location"
                        type="location"
                        inputMode="text"
                        placeholder="Please enter your company's location."
                        {...register('location')}
                      />
                      {errors.location && (
                        <span className="text-red-700 font-regular text-sm lg:text-base leading-tight">
                          {errors.location?.message}
                        </span>
                      )}
                    </label>
                  </div>

                  <div>
                    <label
                      htmlFor="phone"
                      className="grid gap-1 text-base md:text-lg "
                    >
                      Contact Number*
                      <input
                        className="bg-white bg-opacity-40 text-base md:text-lg 2xl:text-lg font-normal placeholder:text-slate-400  text-slate-900 px-3 py-2.5 focus-visible:outline-none outline-none border-1 border-solid border-slate-200 focus-visible:border-slate-500 rounded-lg  "
                        type="number"
                        id="phone"
                        inputMode="numeric"
                        placeholder="Please share your phone number."
                        {...register('phone')}
                      />
                      {errors.phone && (
                        <span className="text-red-700 font-regular text-sm lg:text-base leading-tight">
                          {errors.phone?.message}
                        </span>
                      )}
                    </label>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="message"
                    className="grid gap-1 text-base md:text-lg"
                  >
                    Message
                    <textarea
                      rows={7}
                      cols={5}
                      id="message"
                      className="bg-white bg-opacity-40 text-base md:text-lg 2xl:text-lg font-normal placeholder:text-slate-400  text-slate-900 px-3 py-2.5 focus-visible:outline-none outline-none border-1 border-solid border-slate-200 focus-visible:border-slate-500 rounded-lg   resize-y"
                      placeholder="Got something to say? We're all ears!"
                      {...register('message')}
                    />
                  </label>
                </div>
                <div className="grid place-content-end pt-2">
                  <MasterBtn
                    color="dark"
                    type="submit"
                    text={isLoading ? 'Loading...' : 'Get A Quote'}
                    isDisabled={isLoading}
                    size="large"
                  />
                </div>
              </form>
            </div>
          </div>
        </section>
      </section>
    </>
  )
}
