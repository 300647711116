import React from 'react'
import { BrowserRouter, useRoutes } from 'react-router-dom'
import routes from './router'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import logo from 'assets/logo.svg'

const RenderRoutes = () => {
  const routeTree = useRoutes(routes())
  return routeTree
}

function App() {
  return (
    <BrowserRouter>
      <FloatingWhatsApp
        phoneNumber="+919652306999"
        allowClickAway
        avatar={logo}
        allowEsc
        darkMode
        notificationSound
        accountName="Fitu floors and decors"
      />

      <RenderRoutes />
    </BrowserRouter>
  )
}

export default App
