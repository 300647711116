import React from 'react'
import home_about from 'assets/home_about.png'
import { SocialMedia } from 'organisms/index'

export default function About() {
  return (
    <div id="about-us" className="bg-dark lg:grid grid-cols-12 gap-1">
      <div className="col-start-2  pl-3 col-end-7 max-w-xl pr-10 flex flex-col justify-center gap-6 blade-top-padding blade-bottom-padding">
        <span className="text-cream font-medium leading-tight text-2xl md:text-3xl xl:text-4xl">
          Your trusted partner in creating beautiful living spaces.
        </span>
        <span className="text-cream font-light   text-opacity-80  w-11/12 text-sm md:text-base lg:text-lg ">
          From vitrified tiles to artificial grass flooring, double charge tiles
          to Moroccan tiles, and wooden flooring to much more...
        </span>
        <span className="text-cream font-light  text-opacity-80 w-11/12  text-sm md:text-base lg:text-lg">
          We offer a diverse range of products to suit your style and
          preferences. Our extensive selection ensures that you can discover the
          perfect options for your home.
        </span>
        <span className="text-cream font-medium  text-sm md:text-base lg:text-lg">
          Discover your perfect option with us!
        </span>
        <div className="pt-2 pb-4 md:pt-6">
          <SocialMedia />
        </div>
      </div>
      <div className="col-start-7 col-end-13 h-[300px] md:h-[400px] lg:h-full overflow-hidden ">
        <img
          src={home_about}
          alt="home about"
          className="h-full w-full object-cover object-center"
        />
      </div>
    </div>
  )
}
