import React, { useLayoutEffect } from 'react'
import SubFooter from 'organisms/subFooter'
import './home.css'
import { ContactForm, SocialMedia } from 'organisms/index'
import About from './about'
// import Products from './products'
import Banner from './banner'
import Investors from './investors'
import ProductsStatic from "./productsStatic"

export default function PageHome() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="bg-cream">
      <Banner />
      <About />
      {/* <Products /> */}
      <ProductsStatic />
      <Investors />
    </div>
  )
}
