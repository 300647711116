import React from 'react'
import bannerVideo from 'assets/banner_video.mp4'
import banner from 'assets/home_about.png'

export default function Banner() {
  return (
    <>
      <section className="blade-top-padding-lg about-banner blade-bottom-padding-lg relative ">
        <div className="absolute inset-0">
          <video
            autoPlay
            muted
            loop
            playsInline
            className="h-full w-full  object-cover object-center"
          >
            <source type="video/mp4" src={bannerVideo} />
          </video>
        </div>

        {/* <div className="absolute inset-0 overlay"></div> */}

        <div className="grid grid-cols-12 px-3 blade-bottom-padding-sm absolute  inset-0 ">
          <div className="col-start-1 md:col-start-2 flex flex-col gap-3 justify-end blade-bottom-padding-lg col-end-12 max-w-5xl">
            <h6 className="text-dark font-medium">
              Welcome to Fitu Floors and Decors.
            </h6>
            <span className="text-2xl md:text-3xl 2xl:text-4xl text-dark font-semibold w-11/12 leading-snug">
              We help you transform your home into a stunning oasis
            </span>
          </div>
        </div>
      </section>
    </>
  )
}
