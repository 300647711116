import React from 'react'

export function TitleFloors() {
  return (
    <div className="w-container blade-bottom-padding-sm">
      <h3 className="title w-full text-dark md:block hidden font-semibold">
        Tiles
      </h3>
      <h4 className="title md:hidden leading-snug block w-full text-dark font-semibold ">
        Tiles
      </h4>
      <h6 className="text-dark  text-opacity-80 leading-tight font-medium">
        Wide range of tiles for floor and walls
      </h6>
    </div>
  )
}

export function TitleDecors() {
  return (
    <div className="w-container blade-bottom-padding-sm">
      <h3 className="title w-full text-dark md:block hidden font-semibold  ">
        Decors
      </h3>
      <h4 className="title md:hidden leading-snug block w-full text-dark font-semibold  ">
        Decors
      </h4>
      <h6 className=" text-dark  text-opacity-80 leading-tight font-medium">
        Wide range of products in the decors
      </h6>
    </div>
  )
}
