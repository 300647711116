// hooks to interact with the firestore

import { collection, addDoc, getDocs } from 'firebase/firestore'
import { db, storage } from 'lib/firebase'

import { ref, listAll, getMetadata, getDownloadURL } from 'firebase/storage'

export interface IProduct {
  title: string
  slug: string
  cover: string
}

export const getDecors = async (): Promise<{
  isSuccess: boolean
  data: IProduct[]
}> => {
  const products: Array<IProduct> = []
  const querySnapshot = await getDocs(collection(db, 'decors'))
  querySnapshot.forEach((doc) => {
    products.push(doc.data() as IProduct)
  })

  return { isSuccess: true, data: products }
}


export const getTiles = async (): Promise<{
  isSuccess: boolean
  data: IProduct[]
}> => {
  const products: Array<IProduct> = []
  const querySnapshot = await getDocs(collection(db, 'tiles'))
  querySnapshot.forEach((doc) => {
    products.push(doc.data() as IProduct)
  })

  return { isSuccess: true, data: products }
}


export const getBrochures = async (folder: string): Promise<{
  isSuccess: boolean
  data: IBrochure[]
}> => {
  try {
    const files = await getFilesInfo(folder)
    return { isSuccess: true, data: files }
  } catch (error) {
    console.log('failed to fetch the brochures')
    return { isSuccess: false, data: [] }
  }
}

interface IBrochure {
  url: string
  filename: string
  size: string
}

function bytesToMB(bytes: number): string {
  const mb = bytes / (1024 * 1024)
  return mb.toFixed(2)
}

function formatFilename(filename: string): string {
  let formattedName = filename.replace(/\.(pdf|jpg|jpeg|png|gif|webp|bmp|tiff|svg)$/i, '');
  return formattedName;
}

async function getFilesInfo(folder: string): Promise<IBrochure[]> {
  try {
    const folderRef = ref(storage, folder)
    const fileList = await listAll(folderRef)
    const filesInfo: IBrochure[] = await Promise.all(
      fileList.items.map(async (fileRef) => {
        const metadata = await getMetadata(fileRef)
        const url = await getDownloadURL(fileRef)
        const formattedFilename = formatFilename(metadata.name)
        const sizeInMB = bytesToMB(metadata.size)
        const type = String(metadata.contentType ?? "").startsWith('video') ? 'video' : 'image'

        return {
          url,
          filename: formattedFilename,
          size: sizeInMB,
          type
        }
      }),
    )

    return filesInfo
  } catch (error) {
    console.error('Error retrieving files:', error)
    throw error
  }
}
