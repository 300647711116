import React from 'react'
import logo from 'assets/original_logo.svg'
import { Link, useNavigate } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import logonotext from "assets/logonotext.svg"
import logotext from "assets/logotext.svg"

import { MasterBtn } from 'atoms/buttons'

export default function Header() {
  const router = useNavigate()
  return (
    <header className="fixed top-0  z-[9999] left-0 right-0 bg-dark">
      <nav className="flex items-center w-container-lg  py-2 justify-between gap-x-6">
        <div className="grid place-content-start flex-1 ">
          <Link to="/" className='flex items-end'>
            <img
              src={logonotext}
              alt="logo for fitu Fitu Floors and Decors and manufacturing"
              className="h-8 md:h-10 lg:h-12 -mr-1 w-full object-contain object-center"
            />
            <img
              src={logotext}
              alt="logo for fitu Fitu Floors and Decors and manufacturing"
              className="h-4 md:h-6 lg:h-8 w-full object-contain object-center"
            />

          </Link>
        </div>
        <div className="items-center md:flex hidden gap-x-10">
          <NavLink
            to="/products"
            className={(navData) =>
              (navData.isActive ? 'underline' : '') +
              ' font-medium text-cream outline-none focus-visible:outline-none hover:underline underline-offset-2 decoration-from-font'
            }
          >
            Products
          </NavLink>
          <NavLink
            to="/brochures"
            className={(navData) =>
              (navData.isActive ? 'underline' : '') +
              ' font-medium text-cream outline-none focus-visible:outline-none hover:underline underline-offset-2 decoration-from-font'
            }
          >
            Brochures
          </NavLink>

          <MasterBtn
            color="light"
            type="button"
            onClick={() => router('/contact/?source=general')}
            text="Contact us"
            size="base"
          />
        </div>
        <div className="md:hidden block">
          <MasterBtn
            color="dark"
            type="button"
            onClick={() => router('/contact/?source=general')}
            text="Contact us"
            size="small"
          />
        </div>
      </nav>
    </header>
  )
}
