import React from 'react'

export default function Banner() {
  return (
    <section className="  min-h-[350px] md:min-h-[550px]  flex items-center  bg-no-repeat bg-center bg-cover  blade-top-padding-lg blade-bottom-padding ">
      <div className="grid gap-2 md:gap-3 lg:gap-5 text-center w-container px-4 blade-top-padding-sm">
        <h2 className="title w-full text-dark md:block hidden font-semibold text-center ">
          Everything is designed, But some <br className="md:block hidden" />{' '}
          things are designed Well.
        </h2>
        <h3 className="title md:hidden leading-snug px-4  block w-full text-dark font-semibold text-center ">
          Everything is designed, But some <br className="md:block hidden" />{' '}
          things are designed Well.
        </h3>
        <h6 className="text-center text-dark px-8  text-opacity-80 leading-tight font-medium">
          and we help people with well designed homes.
        </h6>
      </div>
    </section>
  )
}
