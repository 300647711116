import React, { useEffect, useReducer, useState } from 'react'
import Card from './card'
import { getBrochures, getDecors, getTiles } from 'hooks/useFirestore'

export function ListDecors() {
  const [data, setData] = useState<any[]>([{}, {}, {}])
  const [isLoading, endLoading] = useReducer(() => false, true)
  useEffect(() => {
    const getList = async () => {
      const { data, isSuccess } = await getBrochures("decors")
      if (isSuccess) {
        endLoading()
        setData(data)
      }
    }
    getList()
  }, [])
  return (
    <div className=" blade-bottom-padding-lg md:px-6 px-3 lg:px-8 xl:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-container gap-x-4 gap-y-10 pt-4 blade-bottom-padding-lg">
        {data.map((elem, index) => {
          return <Card trigger="decors" isDecor isLoading={isLoading} elem={elem} key={`${index}`} />
        })}
      </div>
    </div>
  )
}

export function ListFloors() {
  const [data, setData] = useState<any[]>([{}, {}, {}])
  const [isLoading, endLoading] = useReducer(() => false, true)
  useEffect(() => {
    const getFloorsCall = async () => {
      const { data, isSuccess } = await getBrochures("tiles")
      if (isSuccess) {
        endLoading()
        setData(data)
      }
    }
    getFloorsCall()
  }, [])
  return (
    <div className=" blade-bottom-padding-lg md:px-6 px-3 lg:px-8 xl:px-10">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-container gap-x-4 gap-y-10  pt-4 blade-bottom-padding-lg">
        {data.map((elem, index) => {
          return <Card trigger="floors" isLoading={isLoading} elem={elem} key={`${index}`} />
        })}
      </div>
    </div>
  )
}
