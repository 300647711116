import React, { useLayoutEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
const data = [

    {
        src: "https://static.asianpaints.com/content/dam/asianpaintsbeautifulhomes/gallery/living-room-tile-designs/decorative-wall-tiles-for-luxury-living-room/decorative-wall-tiles-for-living-room.jpg",
        head: "Tiles",
        target: "/products#tiles",
        para: "Beautiful tiles for your walls and floors.",
    },
    {
        src: "https://images.unsplash.com/photo-1565038941323-e5ceac0fcde2?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        target: "/products#decors",
        head: "Home Decors",
        para: "make your home a serene place with our decors range",
    },
];

const Products = () => {
    const navigate = useNavigate();

    return (
        <section className="blade-top-padding-lg">
            <div className="blade-top-padding blade-bottom-padding px-5 text-center">
                <span className="text-3xl md:text-4xl 2xl:text-5xl title font-medium text-dark">
                    Our Products
                </span>
            </div>


            <div className="flex items-center justify-center gap-7 md:gap-6 xl:gap-8  flex-wrap lg:flex-nowrap px-3 max-w-screen-xl mx-auto md:w-11/12 blade-padding ">
                {data.map((elem, index) => {
                    const { head, src, para, target } = elem;
                    return <DataCard src={src} head={head} para={para} target={target} key={`${index}`} />;
                })}
            </div>
        </section>
    );
};

export default Products;



const DataCard = ({ src, head, para, }: any) => {
    return (
        <Link to="/products" className="basis-96 relative cursor-pointer  flex flex-col self-stretch grow-0 xl:grow overflow-hidden border-2 border-solid border-dark border-opacity-40  shrink mx-auto rounded-2xl group">
            <div >
                <div className="h-[370px] lg:h-[380px] overflow-hidden xl:h-[460px] grow-0 ">
                    <img
                        src={src}
                        alt={head}
                        className="h-full w-full object-cover object-left"
                    />
                </div>
                <div className=" absolute top-auto bottom-0 left-0  bg-gradient-to-b from-darkcream to-darkcream right-0 bg-gradient w-full flex flex-col gap-1  pl-5 pt-3  ">
                    <span className="py-1 text-xl  2xl:text-2xl  font-semibold text-dark   ">
                        {head}
                    </span>
                    <div className=" !text-left text-base xl:text-xl xxl:text-lg  h-0 opacity-0 overflow-hidden group-hover:h-[72px] group-hover:opacity-100  transition-all duration-500 ease-in-out ">
                        {para}

                    </div>
                </div>
            </div></Link>
    );
};
