import React from 'react'
import { Link, useNavigate, useNavigation } from 'react-router-dom'

interface IElem {
  filename: string
  size: string
  url: string
  type: 'video' | 'image'
}
export default function Card({
  elem,
  isDecor = false,
  isLoading,
   trigger
}: {
  isDecor?: boolean
    trigger: string 
  isLoading: boolean
  elem: IElem
}) {
  const { filename, size, url, type } = elem
  const href = `/contact?source=${url}`
  const router = useNavigate()


  return (
    <Link to={`/contact/?source=${trigger}`}>
    <article className="max-w-[350px] cursor-pointer lg:max-w-none  mx-auto w-full">
      <div className="relative rounded-lg overflow-hidden group hover:border-opacity-60 border-opacity-0 border-dark border-[2px]  transition-all duration-300 ease-in-out  border-solid hover:scale-[0.99] scale-100">
        <div className=" w-full bg-dark h-[380px] md:h-[420px] lg:h-[500px] overflow-hidden">
          {isLoading ? (
            ''
          ) : type === 'image' ? (
            <img
              src={url}
              alt={filename}
              className="h-full w-full object-center object-cover"
            />
          ) : (
            <video autoPlay muted loop className="h-full w-full object-center object-cover">
              <source src={url} />
            </video>
          )}
        </div>
        {!isLoading && (
          <div
            className={`${
              isDecor ? 'hidden' : ''
            }  items-start px-4 md:px-7 gap-[2px] h-16 bg-darkcream absolute left-0 right-0 top-auto bottom-0 flex justify-center  flex-col`}
          >
            <span className="text-2xl capitalize font-semibold line-clamp-1 text-dark">
              {filename}
            </span>
            {/* <span className="text-dark text-opacity-90  text-sm md:text-base 2xl:text-lg ">
          {desc}
        </span> */}
          </div>
        )}
      </div>
    </article>
    </Link>

  )
}
