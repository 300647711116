import { ContactForm } from 'organisms/index'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Banner from './banner'
import './contact.css'

export default function Pagecontact() {
  const location = useLocation()
  const [ trigger, setTrigger ] = useState ( "")

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const paramValue = queryParams.get('source')
     if ( paramValue) setTrigger( paramValue)
  }, [])

  return (
    <div className="  bg-cream bg-no-repeat bg-[length:100%_auto] bg-top  bg-[url('assets/contact_banner.png')]">
      <Banner />
      <div className="blade-top-padding-lg blade-bottom-padding ">
        <ContactForm trigger={ trigger} />
      </div>
    </div>
  )
}
